import { createStore as reduxCreateStore } from "redux";

const reducer = (state = {}, action) => {
  if (action.type === "MENUTOGGLE") {
    return Object.assign({}, state, {
      hideMenu: action.bool
    });
  }
  return state;
};

const initialState = {
  hideMenu: false
};

const createStore = () => reduxCreateStore(reducer, initialState);

export default createStore;
